<template>
  <div
    class="horizontal-layout"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >

    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'white' : null,
        boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
      }"
      :toggleable="false"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
      :class="{'fixed-top': $store.getters['app/currentBreakPoint'] !== 'xl'}"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
      >
        <app-navbar-horizontal-layout-brand />
        <app-navbar-horizontal-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        <div class="nav-hover-steps d-none" ref="nav_hover_steps">
          <b-img
            src="@/assets/images/logo/logo-brandme1.png"
            alt="Logo"
            class="logo-brandme-nav-hover"
            @click="$router.push({name: 'dashboard-analytics'}); quitNavbarWhite()"
          />
          <feather-icon icon="XIcon" class="close-icon-nav-hover" size="25" @click="redirectLastRouteLayout()"/>
        </div>
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->

    <div class="horizontal-menu-wrapper">
      <div
        v-if="!isNavMenuHidden"
        class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border d-none d-xl-block"
        :class="[navbarMenuTypeClass]"
      >
        <horizontal-nav-menu />
      </div>
      <div class="container-alert-payment-incomplete" v-if="incomplete_payment">
        <div class="alert-danger-payment">
          {{ $store.getters['main_store/trial_period'].renew_soon ? $t('renewSuscription') : $t('pendingPayment')}} <a @click="navigateToSettings" class="text-white underline-text">{{$t('creator.here')}}</a>.
        </div>
      </div>
      <div class="container-alert-payment-incomplete" v-if="showBannerForProposals">
        <div class="alert-danger-payment">
          {{  $t('msjBanerProposal')}}
        </div>
      </div>
      <div :class="`container-alert-creator ${incomplete_payment ? 'top-12' : 'top-7'}`" v-if="showBanner()">
        <b-card class="alert-dashboard-creator">
          <div class="d-flex justify-content-between align-items-center container-text-dashboard-creator">
            <b-card-text class="subtitle-alert-creator m-0 text-white avenir-medium"> {{$t('dashboard.subtitlePop')}}</b-card-text>
            <b-button variant="light" @click="$router.push({ name: 'setting-public-profile', params: {section: 'connections'}})">{{ $t('dashboard.firstStepsProfile') }}</b-button>
          </div>
        </b-card>   
      </div>
      <!-- Vertical Nav Menu -->
      <vertical-nav-menu
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        class="d-block d-xl-none"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->
    </div>

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>

    <slot name="customizer" />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar, BImg, BCard, BCardText, BButton } from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'

// Content Renderer
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import useLayoutHorizontal from './useLayoutHorizontal'
import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue'

// Vertical Menu
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import mixinLayoutHorizontal from './mixinLayoutHorizontal'
import service from '@/services/others'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { redirectLastRoute } from '@/libs/utils/others'
import { getUserData } from '@/libs/utils/user'
import store from '@/store'


export default {
  components: {
    AppBreadcrumb,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,

    BNavbar,
    BImg,
    BCard,
    BCardText,
    BButton,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,

    // Vertical Menu
    VerticalNavMenu,
  },
  props: {
    userData: {
      type: Object,
      required: false
    }
  },


  mixins: [mixinLayoutHorizontal],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
    isVerifiedEmail() {
      return !this.isVerified
    },
    isRouteAllowed() {
      const routes_no_alert = [
        'setting-public-profile',
        'home_search',
        'additional-information',
        'steps_proposal',
        'stepper-send-pitch',
        'steps_casting_call'
      ];
      return !routes_no_alert.includes(this.$route.name);
    },
    incomplete_payment() {
      return this.isRouteAllowed && (store.getters['main_store/trial_period'].renew_soon || store.getters['main_store/incomplete_payment']);
    },
    showBannerForProposals() {
      const is_banned = store.getters['main_store/banned_for_proposal'];

      if (!is_banned) {
        return false;
      }

      const is_route_allowed = this.isRouteAllowed;
      const is_creator = getUserData() && getUserData().main_group.name === 'creator';

      const result = is_route_allowed && is_creator;

      return result;
    }

  },
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
      is_verified,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      // user data
      is_verified,

      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
    }
  },
  methods: {
    handlePopState() {
      this.quitNavbarWhite();
    },
    redirectLastRouteLayout() {
      redirectLastRoute(this.$router)
      this.quitNavbarWhite();
    },
    metaInfo() {
      return getUserData() && getUserData().meta_info
    },
    showBanner() {
      return (this.$route.name !== 'home_search' && getUserData() && getUserData().main_group && getUserData().main_group.name === 'creator') && 
      (this.$route.name !== 'setting-public-profile') && (this.metaInfo() === null || this.metaInfo().vanity_name === null || this.metaInfo().birthday === '') &&
      this.$route.name !== 'additional-information'
    },
    quitNavbarWhite() {
      this.$refs.nav_hover_steps.classList.add('d-none');
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'static' });
    },
    resend (data) {
      this.isVerified = true
      service.sendEmailPass(data, true)
        .then(() => {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('header.alert.success.title'),
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.$t('header.alert.success.text')
              }
            })
          })
        })
    },
    navigateToSettings() {
      this.$router.push({
        name: 'pages-account-setting',
        params: { section: 'membership' },
        query: { open_update_payment: true, t: Date.now() }
      }).catch(() => { });
    }
  },
  created () {
    this.$root.$on('redirect_last_route', this.redirectLastRoute);
    this.$root.$on('quit_navbar_white', this.quitNavbarWhite);
    window.addEventListener('popstate', this.handlePopState);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  }
}
</script>
<style lang="scss" scoped>
.container-alert-creator {
  position: absolute;
  padding: 0 2em;
  width: 100%;
  height: 100%;
  .alert-dashboard-creator {
    background: linear-gradient(to left bottom, #7cd6af, #3e7ba0);
    .container-text-dashboard-creator {
      @media(max-width: 761px) {
        flex-direction: column;
        align-items: center;
        button {
          margin-top: 0.5em;
          width: 100%;
        }
      }
    }
  }

  @media(max-width: 1199px) {
    padding: 0 1.2em;
  }
}
.top-7 {
  top: 7em;
  .alert-dashboard-creator {
    box-shadow: 0px -23px 5px 10px #f6f6f6;
  }
}
.top-12 {
  top: 12em;
  .alert-dashboard-creator {
    box-shadow: 0px -23px 5px 10px #f6f6f6;
  }

  @media(max-width: 580px) {
    top: 14em;
  }

  @media(max-width: 348px) {
    top: 16em;
  }
}
.container-alert-payment-incomplete {
  position: absolute;
  top: 7em;
  padding: 0 2em;
  width: 100%;
  z-index: 100;
  
  .alert-danger-payment {
    box-shadow: 0px -23px 5px 10px #f6f6f6;
    background-color: #E5272E;
    padding: 1.5em;
    border-radius: 0.7em;
    color: white;
    text-align: center;
  }

  .underline-text {
    text-decoration: underline;
  }

  @media(max-width: 1199px) {
    padding: 0 1.2em;
  }
}
</style>
<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
<style>
.nav-hover-steps {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items : center;
  padding: 1em 6em;
}
.logo-brandme-nav-hover {
  width: 100px;
  height: 33px;
  cursor: pointer;
}
.close-icon-nav-hover {
  transition: background-color 0.3s ease, border-radius 0.3s ease;
}

.close-icon-nav-hover:hover {
  cursor: pointer;
  background-color: #eaeaea;
  border-radius: 100%;
}
</style>
