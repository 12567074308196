import ability from '@/libs/acl/ability'

const baseNavItems = [
  {
    header: 'Campañas',
    img_svg: 'campaign_int_black',
    title: 'campaigns',
    route: 'campaigns',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_campaign'),
    permission: 'view_campaign',
    exclude: ['realty']
  },
  {
    title: 'marketplace',
    img_svg: 'marketplace_black',
    route: 'search_proposal',
    resource: 'Dashboard',
    action: 'manage',
    exclude: ['creator', 'realty'],
    avaible_with_no_session: true,
  },
  {
    header: 'My work',
    title: 'my_work',
    img_svg: 'colaboration_black',
    params: { section: 'campaigns' },
    route: 'my_work_brands',
    resource: 'Brand',
    action: 'manage',
    exclude: ['realty']
  },
  {
    title: 'chat',
    icon: 'MessageCircleIcon',
    route: 'chat-proposal',
    action: 'manage',
    resource: 'Dashboard',
    exclude: ['realty'],
    hasCounter: true,
  },
  {
    header: 'Listas',
    icon: 'ListIcon',
    title: 'list',
    route: 'lists',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_list'),
    permission: 'view_list',
    exclude: ['realty']
  },
  {
    header: 'Tracking Links',
    icon: 'LinkIcon',
    title: 'tracking_links',
    route: 'tracking_links',
    resource: 'Dashboard',
    action: 'manage',
    verify_plan: true,
    plan_benefit: 'view_tracking_links',
    exclude: ['realty']
  },
]

const navItems = [...baseNavItems]

if (process.env.VUE_APP_IS_IN_PRODUCTION !== 'true') {
  navItems.splice(5, 0, {
    header: 'Listas V2',
    icon: 'ListIcon',
    title: 'listv2',
    route: 'lists-v2',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_list'),
    permission: 'view_list',
    exclude: ['realty']
  })
}

export default navItems
